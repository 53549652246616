<template>
  <div id="app">
    <div id="Head" style="">
      <div id="bottons" style="">
        <div id="bottons-img" style="">
          <img src="./assets/logo.png" style="vertical-align: middle" />
        </div>
      </div>
      <div class="divleft1">
        <p class="divleftp1"><strong>就聊交友</strong></p>
        <p class="divleftp2">和Ta处cp</p>
      </div>
      <div class="loadtop">
        <div class="loaddiv1">
          <div class="loaddiv2" id="clickDownBtn"  @click="clickDownBtn">立即下载</div>
        </div>
      </div>
    </div>
    <div style="float: left">
      <img src="./assets/bg.jpg" alt="" class="bg" />
    </div>

    <template v-if="isShowIosTip">
      <div class="shade" @click="shadeClick">
        <img src="./assets/ios_tip.png" alt="" class="bg" />
      </div>
    </template>

    <template v-if="isShowAndroidTip">
      <div class="shade">
        <img src="./assets/weixin_tip.png" alt="" class="bg" />
      </div>
    </template>
  </div>
</template>

<script>
  import { getUserEnv } from "@/api/common";
  import config from "@/api/config";
  import CroptoJs from "crypto-js";
  import { copy } from "iclipboard";
  import axios from "axios";

export default {
  name: "App",
  data() {
    return {
      userEnv: {},
      // 是否点击第一个下载按钮（默认的下载按钮）
      userClickDownBtn: false,
      iv: "",
      // 是否隐藏首页默认的div容器
      hideDefaultDiv: false,
      expire: false,
      config: {},
      user: {},
    };
  },

  created: function () {
    // 获取环境数据
    this.userEnv = getUserEnv();
    this.config = config;
    const iv = this.getUrlKey("iv");
    var keyStr = "!caicai20180314!";
    var key = CroptoJs.enc.Utf8.parse(keyStr);
    var decrypt = CroptoJs.AES.decrypt(iv, key, {
      mode: CroptoJs.mode.ECB,
      padding: CroptoJs.pad.Pkcs7,
    });
    this.user = JSON.parse(CroptoJs.enc.Utf8.stringify(decrypt).toString());

    const _this = this;
    axios
      .get(this.config.apiDomain + "/api/common/getInviteExpire")
      .then(function (res) {
        var keyStr = "!caicai20180315!";
        var key = CroptoJs.enc.Utf8.parse(keyStr);

        var expire = CroptoJs.AES.decrypt(res.data, key, {
          mode: CroptoJs.mode.ECB,
          padding: CroptoJs.pad.Pkcs7,
        });

        var expire1 = JSON.parse(
          CroptoJs.enc.Utf8.stringify(expire).toString()
        );

        var e = _this.user.start + expire1.data.invite_expire;

        if (e * 1000 > Date.now() && _this.user.type === 1) {
          _this.expire = true;
        }
      });
  },
  methods: {
    getUrlKey(name) {
      let reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");
      let r = window.location.search.substr(1).match(reg);
      if (r != null) {
        return unescape(r[2]);
      }
      return null;
    },
    clickDownBtn() {
      // 复制url中的分享邀请码到剪贴板
      if (this.user.code) {
        copy(this.config.sharePrefix + this.user.code);
        axios.get(
          this.config.apiDomain +
            "/api/common/browse?invite_code=" +
            this.user.code
        );
      }
      this.userClickDownBtn = true;
      if (this.userEnv.isAndroid) {
        location.href = this.config.androidUrl;
      }
    },
  },
  computed: {
    //是否显示ios引导页
    isShowIosTip() {
      //点击 && 是微信
      return (
        this.userClickDownBtn &&
        (this.userEnv.isWeiXin || this.userEnv.isQQ) &&
        this.userEnv.isiOS
      );
    },

    //是否显示安卓引导页
    isShowAndroidTip() {
      //点击 && 是微信 或者 qq && 安卓
      return (
        this.userClickDownBtn &&
        (this.userEnv.isWeiXin || this.userEnv.isQQ) &&
        this.userEnv.isAndroid
      );
    },
  },
};
</script>

<style lang="scss">
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
font,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
textarea,
input {
  margin: 0;
  padding: 0;
}

address,
cite,
dfn,
em,
var,
i {
  font-style: normal;
}

body {
  font-size: 16px;
  line-height: 1.5;
  font-family: "Microsoft Yahei", "simsun", "arial", "tahoma";
  color: #222;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

h1,
h2,
h3,
h4,
h5,
h6,
th {
  font-size: 100%;
  font-weight: normal;
}

button,
input,
select,
textarea {
  font-size: 100%;
}

fieldset,
img {
  border: 0;
}

a {
  text-decoration: none;
  color: #666;
  background: none;
}

ul,
ol {
  list-style: none;
}

:focus {
  outline: none;
}

.clearfix {
  clear: both;
  content: "";
  display: block;
  overflow: hidden;
}

.clear {
  clear: both;
}

.fl {
  float: left;
}

.fr {
  float: right;
}

#app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;

  .bg {
    width: 100%;
    height: 100%;
  }

  .shade {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    img {
    }
  }

  .ios-guide {
    .ios-down-div {
      position: fixed;
      bottom: 120px;
      width: 100%;
    }

    .ios-down-div2 {
      position: fixed;
      bottom: 70px;
      width: 100%;
    }

    .ios-down-div3 {
      position: fixed;
      bottom: 20px;
      width: 100%;
    }
  }
  #Head {
    height: 70px;
    width: 100%;
    color: white;
    background-color: white;
    position: fixed;
    bottom: 0;
  }
  #bottons {
    width: 20%;
    height: 100%;
    float: left;
  }
  #bottons-img {
    width: 80px;
    height: 70px;
    display: table-cell;
    vertical-align: middle;
    text-align: center;
  }
  .divleft1 {
    width: 30%;
    height: 70px;
    float: left;
  }
  .divleftp1 {
    text-align: left;
    color: #222222;
    margin-top: 10px;
  }
  .divleftp2 {
    text-align: left;
    color: #666666;
    font-size: 14px;
    margin-top: 5px;
  }
  .loadtop {
    float: right;
    margin-right: 1rem
  }
  .loaddiv1 {
    width: 80px;
    height: 70px;
    display: table-cell;
    vertical-align: middle;
    text-align: center;
  }
  .loaddiv2 {
    background-color: #e5615d;
    color: white;
    border-radius: 10%;
    width: 100px;
    height: 40px;
    line-height: 40px;
  }
}
</style>
